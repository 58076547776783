import * as THREE from 'three';
import { FC, Suspense, useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import {
  EffectComposer,
  DepthOfField,
  Bloom,
  Noise,
  Vignette,
  Scanline,
  Glitch,
  Pixelation,
} from '@react-three/postprocessing';
import {
  Html,
  Icosahedron,
  useTexture,
  useCubeTexture,
  MeshDistortMaterial,
} from '@react-three/drei';
// @ts-ignore
import { BlendFunction, GlitchMode } from 'postprocessing';

const MainSphere: FC<{ material: THREE.Material }> = ({ material }) => {
  const main = useRef<THREE.Mesh>();
  // main sphere rotates
  useFrame(({ clock }) => {
    if (!main.current) {
      return;
    }
    const frame = clock.getElapsedTime();

    main.current.rotation.z = frame % 360;
    main.current.rotation.x = (frame * 0.75 + 120) % 360;
    main.current.rotation.y = (frame * 0.4 + 50) % 360;
  });
  return (
    <Icosahedron
      args={[1, 12]}
      ref={main}
      material={material}
      position={[0, 0, 0]}
      scale={1.3}
    />
  );
};

const Instances: FC<{ material: THREE.Material }> = ({ material }) => {
  // we use this array ref to store the spheres after creating them
  const [sphereRefs] = useState<THREE.Mesh[]>(() => []);
  // we use this array to initialize the background spheres
  const initialPositions = [
    [-4, 20, -12],
    [-10, 12, -4],
    [-11, -12, -23],
    [-16, -6, -10],
    [12, -2, -3],
    [13, 4, -12],
    [14, -2, -23],
    [8, 10, -20],
  ];
  // smaller spheres movement
  useFrame(() => {
    // animate each sphere in the array
    sphereRefs.forEach((el) => {
      el.position.y += 0.02;
      if (el.position.y > 23) el.position.y = -18;
      el.rotation.x += 0.06;
      el.rotation.y += 0.06;
      el.rotation.z += 0.02;
    });
  });
  return (
    <>
      <MainSphere material={material} />
      {initialPositions.map((pos, i) => (
        <Icosahedron
          args={[1, 2]}
          position={[pos[0], pos[1], pos[2]]}
          material={material}
          key={i}
          ref={(ref: THREE.Mesh) => (sphereRefs[i] = ref)}
        />
      ))}
    </>
  );
};

const Scene: FC = () => {
  const bumpMap = useTexture('/background/bump.jpg');
  const envMap = useCubeTexture(
    ['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'],
    { path: '/background/' }
  );
  const [material, set] = useState<THREE.Material>();

  return (
    <>
      <MeshDistortMaterial
        ref={set}
        envMap={envMap}
        bumpMap={bumpMap}
        color="#333"
        roughness={0.2}
        metalness={1}
        bumpScale={0.005}
        clearcoat={1}
        clearcoatRoughness={1}
        radius={1}
        distort={0.6}
      />
      {material && <Instances material={material} />}
    </>
  );
};

export type RetroGlslBackgroundProps = {
  backgroundColor: string;
};

export const RetroGlslBackground: FC<RetroGlslBackgroundProps> = ({
  backgroundColor,
}) => {
  const primaryColor = new THREE.Color(backgroundColor);
  const fogColor = new THREE.Color(backgroundColor);

  return (
    <Canvas
      camera={{ position: [0, 0, 3] }}
      gl={{
        powerPreference: 'high-performance',
        alpha: false,
        antialias: false,
        stencil: false,
        depth: false,
      }}
    >
      <color attach="background" args={[primaryColor]} />
      <fog color={fogColor} attach="fog" near={-20} far={34} />
      <Suspense fallback={<Html center>⏳</Html>}>
        <Scene />
      </Suspense>
      <EffectComposer
        multisampling={0}
        stencilBuffer={false}
        disableNormalPass={true}
        resolutionScale={0.25}
      >
        {/* <DotScreen
          blendFunction={BlendFunction.NORMAL} // blend mode
          angle={Math.PI * 0.5} // angle of the dot pattern
          scale={5.0} // scale of the dot pattern
        /> */}
        <Scanline
          blendFunction={BlendFunction.OVERLAY}
          density={1.2}
          opacity={0.4}
        />
        <DepthOfField
          focusDistance={-5}
          focalLength={0.02}
          bokehScale={8}
          height={480}
        />
        <Glitch
          delay={new THREE.Vector2(5.5, 15.5)}
          duration={new THREE.Vector2(0.6, 1.0)}
          strength={new THREE.Vector2(0.3, 0.6)}
          mode={GlitchMode.SPORADIC}
          chromaticAberrationOffset={new THREE.Vector2(0.6, 1.0)}
          ratio={0.85} // 0 - no weak glitches, 1 - no strong glitches.
          active
        />
        <Bloom
          luminanceThreshold={0}
          luminanceSmoothing={0.9}
          height={200}
          width={200}
          radius={10}
          intensity={2}
          opacity={1}
        />
        <Vignette eskil={false} offset={0.6} darkness={0.4} />
        <Pixelation granularity={2.5} />
        <Noise opacity={0.65} blendFunction={BlendFunction.OVERLAY} />
      </EffectComposer>
    </Canvas>
  );
};
