import { FC } from 'react';
import { useStationContext } from '../hooks/useStationContext';

export const NetworkBanner: FC = () => {
  const { stations } = useStationContext();

  return (
    <div
      style={{
        backgroundColor: 'black',
        fontSize: 10,
        padding: 10,
        lineHeight: '150%',
        color: 'white',
        display: 'flex',
        gap: 15,
      }}
    >
      <strong>Eats&nbsp;Network {'=>'}</strong>
      {stations
        .filter(({ domain }) => domain !== 'localhost')
        .map((n) => (
          <a key={n.domain} style={{ color: 'white' }} href={n.href} target="_top">
            {n.title}
          </a>
        ))}
    </div>
  );
};
