import { createContext, FC, ReactNode, useContext } from 'react';
import { useLocation } from 'react-use';
import config from '../stations.json';

export type StationInfoType = {
  domain: string;
  href: string;
  title: string;

  backgroundColor: string;

  playerEmbedUrl: string;
  streamDownloadUrl: string;
  streamStatsUrl: string;
  streamStatSourceName: string;
  streamUrl: string;
};

export type StationContextType = {
  station: StationInfoType | undefined;
  stations: StationInfoType[];
};

const { networks: stations } = config;

const StationContext = createContext<StationContextType>({
  station: undefined,
  stations,
});

type StationProviderProps = {
  children: ReactNode;
};

export const StationProvider: FC<StationProviderProps> = ({ children }) => {
  const { hostname } = useLocation();
  const station = stations.find(({ domain }) => domain === hostname);

  return (
    <StationContext.Provider value={{ station, stations }}>
      {children}
    </StationContext.Provider>
  );
};

export const useStationContext = () => useContext(StationContext);
