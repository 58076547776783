import { FC, useRef, useState } from 'react';
import { useTitle } from 'react-use';
// import { CloudRadPlayer } from './CloudRadPlayer';
import { BasicPlayer } from './BasicPlayer';
// import { useStationInfo } from '../hooks/useStationInfo';
import { useStationContext } from '../hooks/useStationContext';
import { NetworkBanner } from './NetworkBanner';
import { RetroGlslBackground } from './RetroGlslBackground';

export const PlayerPage: FC = () => {
  const [isPlaying, setPlaying] = useState(false);
  const { station } = useStationContext();
  if (!station) {
    throw new Error('No station? Missing Provider?');
  }

  const player = useRef<HTMLVmPlayerElement>(null);

  // const { listenerCount } = useStationInfo(station.streamStatsUrl || '');
  useTitle(`Eats Network | ${station.title}`);

  return (
    <div
      style={{
        // backgroundColor: station.backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        fontFamily: "'Press Start 2P', cursive",
        color: 'white',
      }}
    >
      <NetworkBanner />
      <RetroGlslBackground backgroundColor={station.backgroundColor} />
      {!isPlaying && (
        <div
          style={{
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
            color: '#FFF',
            fontSize: '1.5em',
            textAlign: 'center',
          }}
          onClick={() => {
            player.current?.play();
          }}
        >
          Click here to listen!
        </div>
      )}
      <div style={{ position: 'absolute', bottom: 0, right: 50, left: 50 }}>
        <BasicPlayer
          streamUrl={station.streamUrl}
          streamStatsUrl={station.streamStatsUrl}
          streamStatSourceName={station.streamStatSourceName}
          playerRef={player}
          onPlaybackChange={(e) => {
            setPlaying(e.detail);
          }}
        />
      </div>
    </div>
  );
};
