import { FC, RefObject, useEffect, useMemo, useState } from 'react';
import { Player, Audio, DefaultUi } from '@vime/react';
// @ts-ignore
import IcecastMetadataStats from 'icecast-metadata-stats';

import '@vime/core/themes/default.css';

type Props = {
  streamUrl: string;
  streamStatsUrl: string;
  streamStatSourceName: string;
  playerRef: RefObject<HTMLVmPlayerElement>;
  onPlaybackChange: (e: CustomEvent<boolean>) => void;
};

export const BasicPlayer: FC<Props> = (props) => {
  const {
    streamUrl,
    streamStatsUrl,
    streamStatSourceName,
    playerRef,
    onPlaybackChange,
  } = props;
  const [nowPlaying, setNowPlaying] = useState('');

  const stats = useMemo(() => {
    return new IcecastMetadataStats(streamStatsUrl, {
      sources: [streamStatSourceName],
      interval: 30,
      onStats: (metadata: any) => {
        setNowPlaying(metadata[streamStatSourceName].StreamTitle);
      },
    });
  }, [streamStatsUrl, streamStatSourceName]);

  useEffect(() => {
    stats?.start();
    return () => stats?.stop();
  }, [stats]);

  return (
    <div>
      <div
        style={{
          fontSize: '.7em',
          marginBottom: '1em',
          lineHeight: '150%',
        }}
      >
        Now Playing:
        <br />
        {nowPlaying}
      </div>
      <Player ref={playerRef} autoplay onVmPlayingChange={onPlaybackChange}>
        <Audio>
          <source src={streamUrl} />
        </Audio>
        <DefaultUi />
      </Player>
    </div>
  );
};
