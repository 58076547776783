import React from 'react';
import { createRoot } from 'react-dom/client';
import { PlayerPage } from './components/PlayerPage';
import { StationProvider } from './hooks/useStationContext';

import './reset.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <StationProvider>
      <PlayerPage />
    </StationProvider>
  </React.StrictMode>
);
